<template>
  <div class="whithout-tab-content">
    <div>
      <!-- <span class="ml-2 summary_refresh_text"
        >Last Updated On: {{ lastUpdated ? lastUpdated : "-" }}</span> -->
      <!-- &nbsp;&nbsp;<span class="summary_refresh">
        <img
          style="cursor:pointer"
          @click="loadData()"
          src="../../assets/refresh.png"
          width="15px"
        />
      </span> -->
      <b-card
        class="m-2 ml-2 mr-2 mt-2 filters"
        :body-class="'card-padding'"
        v-if="isDesktopFilter"
      >
        <div class="row">
          <img
            class="mt-3 close-filter-icon"
            src="../../assets/CloseModel.svg"
            width="15px"
            @click="openDesktop_filter"
          />
          <div class="col-md-3">
            <TreeDropdown
              :labelText="'Channels'"
              :rootOptions="channelList"
              :selectedValue="selectedChannelList"
              @selectedField="getSelectedChannel($event)"
            />
          </div>
          <div class="col-md-3 created-date">
            <label class="tree-label">Created On</label>
            <date-picker
              v-model="selectedCreatedOnDate"
              :lang="'en'"
              range
              confirm
              :shortcuts="[]"
              @clear="clearCreatedOnDate($event)"
              @confirm="getCreatedOnDate($event)"
            ></date-picker>
          </div>
          <div class="col-md-3">
            <TreeDropdown
              :labelText="'Shipment Type'"
              :rootOptions="shipmentTypes"
              :selectedValue="selectedShipmentTypeList"
              @selectedField="selectShipmentType($event)"
            />
          </div>
          <div class="col-md-3">
            <label class="tree-label">Created By</label>
            <w-dropdown
              :label-text="''"
              :selectedValue="selectedCreatedBy"
              :selectOption="createdByList"
              @selectedOption="onSelectCreatedBy($event)"
            />
          </div>
          <div class="col-md-3">
            <label class="tree-label">Shipment ID</label>
            <w-text-input
              :disabled="false"
              :defaultValue="shipmentId"
              :labelStyle="'register-label'"
              :fieldType="'number'"
              @textInputChange="getShipmentId($event)"
            />
          </div>
          <div class="col-md-3">
            <TreeDropdown
              :labelText="'Status'"
              :rootOptions="statusOptions"
              :selectedValue="selectedStatus"
              @selectedField="getStatus($event)"
            />
          </div>
        </div>
          
      </b-card>
      <div class="shipment-table">
        <w-shipment-table
          :tableLoading="tableLoading"
          :tableProperties="tableProperties"
          @showDeleteOption="showDeleteOption"
          @receive="receive($event)"
          @getSelectedPerPageEvent="getSelectedPerPage($event)"
          @getSelectedPageEvent="getSelectedPage($event)"
          @goToShipmentDetailsPageEvent="goToShipmentDetailsPage($event)"
          @onShipmentSettings="onShipmentSettings($event)"
          @createShipmentEvent="showConfirmModal('Mark Ship')"
          @deleteShipmentEvent="showConfirmModal('Delete Ship')"
          @openDesktop_filterEvent="openDesktop_filter"
          :isFilterButton="true"
          :permissionShipment="'allow_mark_shipped_shipments'"
          :permissionDelete="'allow_delete_shipments'"
          :isShipmentPage="true"
        />
      </div>
    </div>
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />

  <!-- Shipment Settings Modal -->
     <b-modal
      id="auto-schedule-modal"
      size="xl"
      hide-footer="true"
      centered
      title="Auto-Scheduling Shipment"
      >
         <div class="row mb-3">
           <div class="col-4 px-0">
             <label class="pr-4">Channel</label>
             <b-form-select 
                v-model="shipmentSettings.selectedChannel" 
                :options="channelList"
                value-field="id"
                text-field="label"
                @change="getShipmentSettings"
              >
              <template #first>
                <b-form-select-option :value="null" disabled>Please select a channel</b-form-select-option>
              </template>
              </b-form-select>
           </div>
         </div>
         <div class="row remmitance-datepicker justify-content-start">
            <div>
              <label class="pr-4">Earliest Pickup Slot</label>
              <b-form-checkbox
                :disabled="isShimentSettingsLoading"
                checked="shipmentSettings.earliestPickupSlot"
                v-model="shipmentSettings.earliestPickupSlot"
                name="earliestPickupSlot"
                switch
                buttons
                button-variant="success"
                style="display: inline-block"
              ></b-form-checkbox>
            </div>
            <div class="ml-5">
              <label class="pr-4">Auto Schedulable</label>
              <b-form-checkbox
                :disabled="isShimentSettingsLoading"
                checked="shipmentSettings.autoSchedulable"
                v-model="shipmentSettings.autoSchedulable"
                name="autoSchedulable"
                switch
                buttons
                button-variant="success"
                style="display: inline-block"
              ></b-form-checkbox>
            </div>            
          </div>
          <div class="row remmitance-datepicker justify-content-between pt-4">
            <div>
              <label class="mb-0">Email Id</label>
              <div v-for="(email,i) in shipmentSettings.emails" :key="`email-${i+1}`" class="d-flex align-items-center">
                <w-text-input
                  class="mt-2"
                  newInputStyle="h-37"
                  placeholder="Email Id"
                  :fieldType="'email'"
                  :disabled="isShimentSettingsLoading"
                  :defaultValue="email"
                  :validation="'regex_Email'"
                  :labelStyle="'register-label'"
                  :fieldName="`Email-${i+1}`"
                  @textInputChange="onUpdateEmail(i,$event)"
                />
                <span v-if="i>0" @click="removeEmail(i)" class="ml-2 pointer">
                  <img src="../../assets/CloseModel.svg" height="15px" width="15px">
                </span>
              </div>              
              <div class="my-2" style="color:#2e8cd3;cursor:pointer" @click="addEmail">
                <img src="../../assets/add.png">
                <span class="ml-2 scheduling-time"><small>Add an Email Id</small></span>
              </div>
            </div>
            <div>
              <label>Auto Schedule</label>
              <date-picker
                :disabled="isShimentSettingsLoading"
                v-model="shipmentSettings.selectedScheduleDateRange"
                id="auto-schedule"
                :lang="'en'"
                range
                confirm
                format="DD/MM/YYYY"
                :shortcuts="[]"
                @clear="clearEarliestPickupSlot($event)"
                @confirm="getEarliestPickupSlot($event)"
              ></date-picker>
            </div>
            <div>
              <label class="d-block mb-0">Auto Scheduling Time</label>
              <div v-for="(time,i) in shipmentSettings.autoSchedulingTimeList" :key="i">
                <div class="d-flex align-items-center">
                  <date-picker
                    class="mt-2"
                    style="min-width:250px"
                    :disabled="isShimentSettingsLoading"
                    :value="time"
                    type="time"
                    format="HH:mm:ss"
                    placeholder="Select Time"
                    @change="onTimeChanged($event,i)"
                    show-time-header="false"
                    use12h="true"
                  ></date-picker>
                  <span v-if="i>0" @click="removeTime(i)" class="ml-2 pointer">
                    <img src="../../assets/CloseModel.svg" height="15px" width="15px">
                  </span>
                </div>
              </div>
              <div class="my-2" style="color:#2e8cd3;cursor:pointer" @click="addTime">
                <img src="../../assets/add.png">
                <span class="ml-2 scheduling-time"><small>Add a Scheduling Time</small></span>
              </div>
            </div>
          </div>
          <div class="row justify-content-center mt-5 py-2">
            <button class="btn btn-primary mr-3" style="min-width:115px" :disabled="isShimentSettingsLoading" @click="onShipmentSettingsSave">Save</button>
            <button class="btn btn-outline-primary" style="min-width:115px" :disabled="isShimentSettingsLoading" @click="onShipmentSettingsClose">Cancel</button>
          </div>
        <div class="py-3"></div>
      </b-modal>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import TextInput from "../../widgets/TextInput.vue";
import TableWithCheckbox from "../../widgets/TableWithCheckbox.vue";
import TreeDropdown from "../../widgets/TreeDropdown";
import Modal from "../../widgets/ModalWidget";
import moment from "moment";
import Dropdown from "../../widgets/Dropdown.vue";

import {
  getShipmentListWithFilter,
  getShipmentStatus,
  getShipmentTypes,
  markShippedBulk,
  deleteShipment,
  getCreatedByList,
  getAutoScheduleSettings,
  updateAutoScheduleSettings
} from "../../Service/ShipmentService";
// import { getUserList } from "../../Service/CommonService";
import { getChannelList } from "../../Service/CommonService";
export default {
  components: {
    DatePicker,
    "w-text-input": TextInput,
    "w-shipment-table": TableWithCheckbox,
    TreeDropdown,
    Modal,
    "w-dropdown": Dropdown,
  },
  data() {
    return {
      isDesktopFilter:false, // for filter to open
      companyID:sessionStorage.getItem("companyIds"),
      tableLoading: false,
      popType: "",
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,
      channelList: [],
      statusOptions: [],
      selectedStatus: [],
      shipmentId: "",
      selectedChannelList: [],
      selectedShipmentTypeList: [],
      shipmentTypes: [],
      selectedCreatedOnDate: [],
      createdByList: [],
      selectedCreatedBy: null,
      tableProperties: {
        fields: [
          {
            key: "checkbox",
            label: "",
            selected: false,
            tdClass: "td-checkbox-class",
          },
          {
            key: "channelName",
            label: "Channel",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "orderShipmentBatchId",
            label: "Shipment ID",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "shipmentType",
            label: "Shipment Type",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "status",
            label: "Status",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "createdOn",
            label: "Created Date",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: true,
          },
          {
            key: "createdBy",
            label: "Created By",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "orderIds",
            label: "No. Of Orders",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          // {
          //   key: "actiond_field",
          //   label: "Action",
          //   selected: false,
          //   thClass: "thClass",
          //   tdClass: "tdClass",
          // },
        ],
        items: [],
        perPage: 50,
        currentPage: 1,
        totalRows: 0,
        allSelected: false,
        shipmentShowButton: true,
      },
      // companyId: 7,
      startDate: "",
      endDate: "",
      selectedSortBy: "ASC",
      // lastUpdated:'',
      shipmentSettings: {
        selectedChannel: null,
        emails: [''],
        earliestPickupSlot : false,
        autoSchedulable: false,
        selectedScheduleDateRange: [],
        startDate: '',
        endDate: '',
        autoSchedulingTimeList: [null]
      },
      isShimentSettingsLoading: false
    };
  },
  created() {
    this.getChannelList();
    this.getShipmentType();
    this.getOrderStatus();
    // this.loadData();
    this.getShipmentList();
    this.getCreatedByList();
  },
  methods: {
    getShipmentSettings() {
      this.isShimentSettingsLoading = true;
      getAutoScheduleSettings(this.shipmentSettings.selectedChannel).then(res=> {
        this.isShimentSettingsLoading = false;
        let {
          autoScheduleEndDate,
          autoScheduleStartDate,
          autoSchedulingTimeList,
          emailIdList,
          isAutoScheduled,
          isEarliestPickupSlot
        } = res;
        let dateRange = autoScheduleStartDate && autoScheduleEndDate ? [new Date(autoScheduleStartDate), new Date(autoScheduleEndDate)] : [];        
        let times = autoSchedulingTimeList.map(time => new Date(`2022-01-01T${time}`));
        this.shipmentSettings.earliestPickupSlot = !!isEarliestPickupSlot;
        this.shipmentSettings.autoSchedulable = !!isAutoScheduled;
        this.shipmentSettings.emails = emailIdList ? emailIdList : [''];
        this.shipmentSettings.selectedScheduleDateRange = dateRange;
        this.shipmentSettings.autoSchedulingTimeList = autoSchedulingTimeList ? times : [null];
      }).catch(err=> {
        this.isShimentSettingsLoading = false;
        console.log(err);
      })
    },
    onShipmentSettingsSave() {
      let { 
        emails: emailIdList, 
        autoSchedulable: isAutoScheduled,
        earliestPickupSlot: isEarliestPickupSlot,
        startDate: autoScheduleStartDate,
        endDate: autoScheduleEndDate,
        autoSchedulingTimeList,
        selectedChannel
      } = this.shipmentSettings;
      autoSchedulingTimeList = autoSchedulingTimeList.map(time => moment(time).format('HH:mm:ss'));
      let payload = {
        emailIdList,
        isAutoScheduled,
        isEarliestPickupSlot,
        autoScheduleStartDate,
        autoScheduleEndDate,
        autoSchedulingTimeList
      }
      updateAutoScheduleSettings(payload, selectedChannel).then(res=> {
        console.log(res);
        this.$bvModal.hide("auto-schedule-modal");
      }).catch(err=> {
        console.log(err);
      })
    },
    onTimeChanged(e,i) {
      this.shipmentSettings.autoSchedulingTimeList[i] = e;
      // Below two lines are just a workaround to trigger the page DOM update - we're just adding and removing an item into array
      this.shipmentSettings.autoSchedulingTimeList.push(null);
      this.shipmentSettings.autoSchedulingTimeList.pop();
    },
    addTime() {
      this.shipmentSettings.autoSchedulingTimeList.push(null);
    },
    removeTime(i) {
      this.shipmentSettings.autoSchedulingTimeList.splice(i,1);
    },
    getEarliestPickupSlot() {
      this.shipmentSettings.startDate = moment(this.shipmentSettings.selectedScheduleDateRange[0]).valueOf();
      this.shipmentSettings.endDate = moment(this.shipmentSettings.selectedScheduleDateRange[1]).valueOf();
    },
    clearEarliestPickupSlot() {
      this.shipmentSettings.selectedScheduleDateRange = [];
      this.shipmentSettings.startDate = "";
      this.shipmentSettings.endDate = "";
    },
    onShipmentSettings(e) {
      // resets shipment settings form values
      this.shipmentSettings.earliestPickupSlot = false;
      this.shipmentSettings.autoSchedulable = false;
      this.shipmentSettings.emails = [''];
      this.shipmentSettings.selectedScheduleDateRange = [];
      this.shipmentSettings.autoSchedulingTimeList = [null];
      this.shipmentSettings.startDate = "";
      this.shipmentSettings.endDate = "";  
      this.shipmentSettings.selectedChannel = null;
      // opens modal
      this.$bvModal.show("auto-schedule-modal");
    },
    onShipmentSettingsClose() {
      this.$bvModal.hide("auto-schedule-modal");
    },
    onUpdateEmail(index, value) {
      this.shipmentSettings.emails[index] = value;
    },
    addEmail() {
      this.shipmentSettings.emails.push('');
    },
    removeEmail(i) {
      this.shipmentSettings.emails.splice(i,1);
    },
    // loadData() {
    //   // this.getLastUpdated();
    //   this.getShipmentList();
    // },
    // To get  last updated time
    // getLastUpdated() {
    //   this.lastUpdated = moment().format("MM/DD/YYYY hh:mm:ss a");
    // },

     // for created by
    getCreatedByList(){
      this.createdByList = [];
      let createdList = [];

      this.createdByList.push({
        text:"Select",
        value:null
      })

      getCreatedByList()
      .then((res) => {
        createdList= res;
        createdList.forEach((el) => {
          this.createdByList.push({
            text:el.email,
            value:el.email
          })
        })
      })
      .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // getUserList() {
    //   this.createdByList = getUserList();
    // },
    getOrderStatus() {
      getShipmentStatus()
        .then((res) => {
          if (!res.errorCode) {
            this.statusOptions = [];
            let resp = res.response;
            resp.forEach((element) => {
              this.statusOptions.push({
                id: element,
                label: element,
              });
            });
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    getShipmentList() {
      this.tableLoading = true;
      getShipmentListWithFilter(
        this.companyID,
        this.tableProperties.currentPage - 1,
        this.tableProperties.perPage,
        this.startDate,
        this.endDate,
        this.shipmentId,
        this.selectedChannelList,
        this.selectedShipmentTypeList,
        this.selectedStatus,
        this.selectedCreatedBy
      )
        .then((res) => {
          this.tableLoading = false;
          // this.tableProperties.items = res;
          if (!res.errorCode) {
            this.tableProperties.items = [];
            let resp = res.response;
            this.tableProperties.items = resp.content;
            this.tableProperties.items.forEach((item) => {
              item.selected = false;
              // var createdOn = item.createdOn ? item.createdOn.replace("T", " ") : '';
              // createdOn = createdOn.slice(0,19);
              // console.log(createdOn, " created")
              // item.createdOn = createdOn != '' ? this.getPurchaseDateZoneWise(createdOn) : ' - ';
            });
            this.tableProperties.allSelected = false;
            this.tableProperties.totalRows = resp.totalElements;
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    getPurchaseDateZoneWise(dateAndTime){
      let time = moment.utc(dateAndTime,'YYYY-MM-DD hh:mm:ss').local().format('DD/MM/YYYY hh:mm A');
      return time;
    },
    getChannelList() {
      getChannelList(this.companyID)
        .then((res) => {
          res.forEach((element) => {
            this.channelList.push({
              id: element.channelId,
              label: element.channelName,
            });
          });
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    getShipmentType() {
      getShipmentTypes()
        .then((res) => {
          if (!res.errorCode) {
            let resp = res.response;
            resp.forEach((element) => {
              this.shipmentTypes.push({
                id: element,
                label: element,
              });
            });
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    clearCreatedOnDate() {
      this.startDate = "";
      this.endDate = "";
      this.getShipmentList();
    },
    getCreatedOnDate() {
      if (this.selectedCreatedOnDate.length > 1) {
        this.startDate = moment(this.selectedCreatedOnDate[0]).valueOf();
        this.endDate = moment(this.selectedCreatedOnDate[1]).valueOf();
        this.getShipmentList();
      }
    },
    getShipmentId(event) {
      this.shipmentId = event;
      this.getShipmentList();
    },
    getStatus(event) {
      this.selectedStatus = event;
      this.getShipmentList();
    },
    getSelectedChannel(event) {
      this.selectedChannelList = event;
      this.getShipmentList();
    },
    selectShipmentType(event) {
      this.selectedShipmentTypeList = event;
      this.getShipmentList();
    },
    getSelectedPaymentTypes(event) {
      this.selectedPaymentList = event;
      this.getShipmentList();
    },
    getSelectedPage(currentPage) {
      this.tableProperties.currentPage = currentPage;
      this.tableProperties.allSelected = false;
      this.getShipmentList();
    },
    onSelectCreatedBy(event) {
      this.selectedCreatedBy = event;
      this.getShipmentList();
    },
    getSelectedPerPage(perPage) {
      this.tableProperties.perPage = perPage;
      this.tableProperties.currentPage = 1;
      this.tableProperties.allSelected = false;
      this.getShipmentList();
    },
    // Code for order details
    goToShipmentDetailsPage(shipment) {
      // for upload shipment template we need channel Id and company id
      // console.log("shipment => ", shipment)
      sessionStorage.setItem("channelID",shipment.channelId);
      sessionStorage.setItem("companyID",shipment.companyId);
      sessionStorage.setItem("shipmentID",shipment.orderShipmentBatchId)

      if (shipment.orderShipmentBatchId) {
        sessionStorage.setItem(
          "orderShipmentBatchId",
          shipment.orderShipmentBatchId
        );
        sessionStorage.setItem("backToPage", "Shipment");
        this.$router.push("/shipment-details");
      }
    },
    goBackToOrder() {
      this.isOrderDetailsTab = false;
    },
    confirmOk() {
      if (this.popType === "Mark Ship") {
        this.markShippedBulk();
      } else if (this.popType === "Delete Ship") {
        this.deleteShipment();
      }
    },
    showConfirmModal(type) {
      this.alert = true;
      this.alertMsg =
        type === "Mark Ship"
          ? "Are you sure want to mark as shipped ?"
          : "Are you sure want to delete shipment ?";
      this.alertType = "";
      this.popType = type;
      this.isConfirmAlert = true;
    },
    markShippedBulk() {
      let shipmentIds = [];
      this.tableProperties.items.forEach((item) => {
        if (item.selected) {
          shipmentIds.push(item.orderShipmentBatchId);
        }
      });
      if (shipmentIds.length > 0) {
        markShippedBulk(this.companyID, shipmentIds)
          .then((res) => {
            this.alert = true;
            this.alertMsg = res.status === "FAILURE" ? res.failureMessage : res.response;
            this.alertType = "";
            this.isConfirmAlert = false;
            this.tableProperties.perPage = 50;
            this.tableProperties.currentPage = 1;
            this.tableProperties.allSelected = false;
            this.getShipmentList();
          })
          .catch((err) => {
            this.alert = true;
            this.alertMsg = err.message;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
            this.getShipmentList();
          });
      } else {
        this.alert = true;
        this.alertMsg = "Please select atleast one shipment to proceed..";
        this.alertType = "Failure";
        this.isConfirmAlert = false;
      }
    },
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
      this.popType = "";
    },
    deleteShipment() {
      let shipmentIds = [];
      this.tableProperties.items.forEach((item) => {
        if (item.selected) {
          shipmentIds.push(item.orderShipmentBatchId);
        }
      });
      if (shipmentIds.length > 0) {
        deleteShipment(shipmentIds)
          .then((res) => {
            this.alert = true;
            this.alertMsg = res.status === "FAILURE" ? res.failureMessage : res.response;
            this.alertType = "";
            this.isConfirmAlert = false;
            this.tableProperties.perPage = 50;
            this.tableProperties.currentPage = 1;
            this.tableProperties.allSelected = false;
            this.getShipmentList();
          })
          .catch((err) => {
            this.alert = true;
            this.alertMsg = err.message;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
            this.getShipmentList();
          });
      } else {
        this.alert = true;
        this.alertMsg = "Please select atleast one shipment to proceed..";
        this.alertType = "Failure";
        this.isConfirmAlert = false;
      }
    },

    // open desktop filter
    openDesktop_filter(){
      this.isDesktopFilter = !this.isDesktopFilter;
    },

  },
};
</script>
<style scoped>
::v-deep .sub-content-tab {
  height: 0 !important;
}
.filters ::v-deep .custom-select, .filters ::v-deep .form-control{
  height: 37px !important;
}
.shipment-filter {
  margin-top: 0 !important;
}
/* .card-padding {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
  padding-top: 0 !important;
} */
.active-class {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  color: #2e8cd3;
  cursor: pointer;
}
.inactive-class {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  color: #666666;
  cursor: pointer;
}
.striped-class {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.non-striped-class {
  background-color: #ffffff !important;
}
.back-button {
  width: 149px;
  height: 33px;
  border-radius: 2px;
  border: solid 1px #5fa6e7;
  background-color: #5fa6e7;
  line-height: normal;
}
.shipment-table ::v-deep table thead tr th:first-child{
  z-index: 1;
}
.shipment-table ::v-deep table thead tr th{
  text-align: center !important;
}
.shipment-table ::v-deep table tbody tr td{
  text-align: center !important;
}
.shipment-table ::v-deep table thead tr th:nth-child(2){
  text-align: left !important;
}
.shipment-table ::v-deep table tbody tr td:nth-child(2){
  text-align: left !important;
}
.shipment-table ::v-deep .desktop-download-ship-icons img:nth-child(2){
  margin-left: 20px !important;
}
/* for responsive */
@media screen and (max-width: 768px) {
  .whithout-tab-content{
    position: relative;
    top: -40px;
  }
  .created-date ::v-deep .mx-range-wrapper {
    width: 100%;
  }
  .created-date ::v-deep .mx-calendar {
    width: 100%;
  }
  .created-date ::v-deep .mx-calendar-content {
    width: 100%;
  }
  .created-date ::v-deep .mx-datepicker-popup {
    left: 0px !important;
  }
}
@media only screen and (max-width: 340px){
  .shipment-table ::v-deep .table-pagination .page-link{
    min-width: 10px !important;
  }
}
.close-filter-icon{
  cursor: pointer;
  position: absolute;
  right: 10px;
  z-index: 2;
  top:0;
}
</style>
