<template>
  <div>
    <shippment />
  </div>
</template>

<script>
import Shippment from "../components/Shipments/Shippment.vue";
export default {
  components: {
    Shippment,
  },
};
</script>
